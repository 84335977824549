.Settings {
  background: white;
  box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 37rem;
  height: 28rem;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  .tabs,
  .activeTab {
    padding: 10px;
  }

  .tabs {
    background: rgb(240, 240, 240);
    border-right: 1px solid rgb(227, 227, 227);
    flex: 1;
    padding-top: 3rem;

    .close {
      top: 10px;
      left: 10px;
      position: absolute;
      border-radius: 7px;
      padding: 2px;

      &:hover {
        cursor: pointer;
        background: rgb(220, 220, 220);
      }
    }

    .tab {
      padding: 7px;
      border-radius: 7px;
      cursor: pointer;
      user-select: none;

      &.active {
        background: rgb(215, 215, 215);
      }

      &:hover {
        &:not(.active) {
          background: rgb(230, 230, 230);
        }
      }
    }
  }

  .activeTab {
    flex: 2;
    overflow: auto;

    h3 {
      margin-bottom: 1.5rem;
    }

    .setting {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .input {
      display: flex;
      gap: 10px;
      width: 100%;

      input {
        width: 100%;
      }
    }

    .workspace {
      .icon {
        width: 100%;
        height: 3rem;
        display: grid;
        place-items: center;
        background: rgb(225, 225, 225);
        border-radius: 12px;
        color: rgba(0, 0, 0, 0.504);
        font-weight: 700;
        font-size: 1.2rem;
        position: relative;
      }

      .edit {
        border: none;
        background: rgb(0, 0, 0);
        padding: 0;
        border-radius: 12px;
        aspect-ratio: 1 / 1;

        .icon {
          background: none;
          color: white;
        }
      }
    }

    .account {
      .settings {
        .profilePicture {
          width: 60px;
          height: 60px;
          border-radius: 12px;
          overflow: hidden;
          position: relative;

          button {
            background: rgba(41, 41, 41, 0.275);
            color: white;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: inherit;

            &:hover {
              background: rgba(41, 41, 41, 0.5);
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .Settings {
    width: 100vw;
    height: 100vh;
    border-radius: none;
  }
}
