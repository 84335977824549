.Settings {
  background: white;
  box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 37rem;
  height: 28rem;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  .tabs,
  .activeTab {
    padding: 10px;
  }

  .tabs {
    background: rgb(240, 240, 240);
    border-right: 1px solid rgb(227, 227, 227);
    flex: 1;
    padding-top: 3rem;

    .close {
      top: 10px;
      left: 10px;
      position: absolute;
      border-radius: 7px;
      padding: 2px;

      &:hover {
        cursor: pointer;
        background: rgb(220, 220, 220);
      }
    }

    .tab {
      padding: 7px;
      border-radius: 7px;
      cursor: pointer;
      user-select: none;

      &.active {
        background: rgb(215, 215, 215);
      }

      &:hover {
        &:not(.active) {
          background: rgb(230, 230, 230);
        }
      }
    }
  }

  .activeTab {
    flex: 2;
    overflow: auto;

    .account {
      .settings {
        .profilePicture {
          width: 60px;
          height: 60px;
          border-radius: 12px;
          overflow: hidden;
          position: relative;

          button {
            background: rgba(41, 41, 41, 0.275);
            color: white;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: inherit;

            &:hover {
              background: rgba(41, 41, 41, 0.5);
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .Settings {
    width: 100vw;
    height: 100vh;
    border-radius: none;
  }
}
