@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=VT323&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
  font-family: "Inter", sans-serif;

  &::selection {
    background: black;
    color: white;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;

  th {
    text-align: left;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
  }

  tr {
    padding-left: 20px;
  }

  tr:nth-child(even) {
  }

  tr:hover:not(:nth-child(1)) {
    background-color: #f9f9f9;
  }
}

button {
  background: black;
  border: none;
  color: white;
  padding: 5px 15px;
  border-radius: 999px;
  cursor: pointer;
  transition: transform 0.25s ease, box-shadow 0.25s ease, background 0.25s ease;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0);

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
    background: rgb(10, 10, 10);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0);
  }

  &.danger {
    background: rgb(255, 67, 67);
  }
}

input,
textarea {
  background: rgb(234, 234, 234);
  border: none;
  color: black;
  padding: 5px 15px;
  border-radius: 999px;

  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

h3 {
  margin-bottom: 1.5rem;
}

.setting {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input {
  display: flex;
  gap: 10px;
}

.save {
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
  gap: 10px;

  button {
    width: 100%;
  }
}

textarea {
  border-radius: 7px;
  height: 10rem;
  resize: none;
  padding: 10px;
}

.ModalManager {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  isolation: isolate;
  pointer-events: none;
  z-index: 99999;

  .modal {
    pointer-events: all;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    isolation: isolate;
    display: flex;
    justify-content: center;
    align-items: center;

    .bg {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.137);
      backdrop-filter: blur(5px);
    }
  }
}

@media screen and (max-width: 1080) {
  .ModalManager {
    .modal {
      .modalBody {
        padding: 20px !important;
      }
    }
  }
}
