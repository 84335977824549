.Account {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  padding-right: 30px;
  border-radius: 12px;
  user-select: none;
  cursor: pointer;
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  background: white;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  min-width: 12rem;

  img {
    object-fit: cover;
    width: 3rem;
    height: 3rem;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  .info {
    .name {
      font-size: 1.3rem;
    }

    .email {
      font-size: 0.9rem;
      color: grey;
    }
  }

  .dropdown {
    position: absolute;
    background: white;
    bottom: Calc(100%);
    left: 0;
    background: white;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
    width: Calc(100% - 17px);
    padding: 10px;
    border-radius: 12px;
    transition: bottom 0.25s ease, opacity 0.25s ease;
    opacity: 0;
    pointer-events: none;

    .workspaces {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .workspace {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        border-radius: 12px;

        &.active {
          background: rgb(242, 242, 242);
        }

        .icon {
          width: 3rem;
          height: 3rem;
          display: grid;
          place-items: center;
          background: rgb(225, 225, 225);
          border-radius: 12px;
          color: rgba(0, 0, 0, 0.504);
          font-weight: 700;
          font-size: 1.2rem;
        }

        &:hover {
          background: rgb(242, 242, 242);
        }
      }
    }

    .item {
      background: none;
      border-radius: 12px;
      padding: 7px;

      &:hover {
        background: rgb(241, 241, 241);
      }

      &.logout {
        &:hover {
          background: rgb(255, 230, 230);
        }
      }

      &.logout {
        color: rgb(255, 67, 67);
      }
    }
  }

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus-within,
  &.open {
    transform: translateY(-2px);

    .dropdown {
      bottom: Calc(100% + 10px);
      opacity: 1;
      pointer-events: all;
    }
  }
}

.newWorkspace {
  background: white;
  box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  button {
    width: max-content;
  }
}
