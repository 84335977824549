.Dashboard {
  display: flex;
  width: 100%;
  height: 100vh;

  &.active {
  }

  .timer,
  .backlog {
    flex: 1;
  }

  .timer {
    display: grid;
    place-items: center;
    background: white;

    transition: background 0.25s ease;

    &.active {
      background: rgb(242, 255, 242);
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      h1 {
        font-size: 5rem;
        font-family: "Roboto Mono", sans-serif;
        transition: font-size 0.15s ease;

        &.active {
          font-size: 5.5rem;
        }
      }

      button {
        font-size: 1.2rem;
        padding: 10px 25px;
      }
    }
  }

  .backlog {
    background: white;
    overflow-y: auto;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: -10px 0px 15px -3px rgba(0, 0, 0, 0.05);

    .title {
      margin-bottom: 30px;
      color: rgb(104, 104, 104);
    }

    .day {
      width: 100%;
      .seperator {
        width: 100%;
        display: flex;
        justify-content: center;
        isolation: isolate;
        position: sticky;
        top: -32px;

        &::after {
          content: "";
          width: 100%;
          height: Calc(50% - 1px);
          background: none;
          position: absolute;
          border-bottom: 1px solid rgb(147, 147, 147);
          background: white;
          left: 0;
          top: 0;
          z-index: -1;
        }

        .date {
          background: white;
          width: max-content;
          border: 1px solid rgb(147, 147, 147);
          border-radius: 999px;
          padding: 5px 10px;
          isolation: isolate;
          color: rgb(0, 0, 0);
          cursor: pointer;
          user-select: none;

          &:hover {
            background: rgb(233, 233, 233);
          }
        }
      }

      table {
        padding: 0 20rem;

        .actions {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}

.viewBacklog {
  display: none;
}

@media screen and (max-width: 1080px) {
  .backlog:not(.mobileView) {
    display: none !important;
  }

  .backlog.mobileView {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .Account.mobileView {
    display: none;
  }

  .viewBacklog {
    display: flex;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: black;
    color: white;
    z-index: 2;
  }
}

@media screen and (max-width: 515px) {
  .timer {
    h1 {
      font-size: 4rem !important;
    }
  }
}
