.WorksDetails {
  background: white;
  padding: 10px;
  border-radius: 12px;
  width: 20rem;
  height: 15rem;
  overflow-y: auto;

  h3 {
    margin-bottom: 0;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;

    .icon {
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: rgb(231, 231, 231);
      }
    }
  }

  .settings {
    display: flex;
    flex-direction: column;

    .setting {
      display: flex;
      flex-direction: column;

      input {
        width: 100%;
      }
    }
  }
}
